import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import ParallaxImage from '@objects/parallaxImage'
import Card from '@objects/card'
import Button from '@objects/button'
import CreateContentButton from '@objects/button/createContentButton'
import Headline from '@objects/headline'
import { focalPointsTable } from '@tools/focalPoints'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  stageRoot: {
    marginBottom: theme.spacing(15),
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      marginBottom: theme.spacing(23),
    },
  },
  card: {
    zIndex: 100,
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(-20),
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
      margin: 0,
      paddingLeft: `calc(50% - ${parseInt(theme.container['lg'], 10) / 2}px)`,
    },
  },
  image: {
    minHeight: '400px',
    maxHeight: '65vh',
  },
  imageMobile: {
    minHeight: '250px',
    maxHeight: '65vh',
  },
  mediaPageImage: {
    minHeight: '220px',
    maxHeight: '65vh',
    [theme.breakpoints.up('md')]: {
      minHeight: '500px',
    },
  },
  stageImageGeneric: {
    textAlign: 'right',
    backgroundColor: theme.palette.background.grey,
    overflow: 'hidden',
  },
  genericImageEl: {
    transform: 'scale(1.7) translateX(-20%)',
    maxWidth: '57vw',
    [theme.breakpoints.up('md')]: {
      maxWidth: 'none',
    },
  },
  headline: {
    marginBottom: theme.spacing(4),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  subline: {
    color: theme.palette.red.main,
    ...theme.typography.h5,
  },
  button: {
    marginTop: theme.spacing(4),
    '&:not(:last-child)': {
      marginRight: theme.spacing(8),
    },
  },
  articleDate: {
    marginTop: theme.spacing(5),
    ...theme.typography.info,
  },
  authorWrapper: {
    width: '100%',
    maxWidth: theme.container['lg'],
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      margin: theme.spacing(-5, 'auto', 0, 'auto'),
    },
  },
  author: {
    display: 'inline-flex',
    minWidth: '250px',
    background: theme.palette.background.default,
    borderRadius: '8px',
    padding: theme.spacing(5, 10, 5, 5),
  },
  authorAvatar: {
    overflow: 'hidden',
    marginRight: theme.spacing(5),
    borderRadius: '999px',
    height: theme.spacing(10),
    width: theme.spacing(10),
    '& img': {
      maxWidth: '100%',
    },
  },
  authorDetails: {
    ...theme.typography.info,
  },
  alignTop: {
    /* underlay breadcrumbs */
    [theme.breakpoints.up('lg')]: {
      marginTop: '-60px',
    },
  },
  noMargin: {
    margin: 0,
  },
}))

function Stage({ className, children, ...props }) {
  const classes = useStyles(props)
  const {
    type,
    title,
    subtitle,
    copy,
    buttons,
    image,
    imageMobile,
    date,
    author,
    alignTop,
    cardBar,
    noMargin,
    focalPoint,
    ariaLabel,
  } = props

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const intl = useIntl()
  const renderImage = () => {
    switch (type) {
      case 'generic':
        return (
          <div className={classes.stageImageGeneric}>
            <img
              className={classes.genericImageEl}
              src="/img/illustration/illustration_colored.svg"
              alt=""
            />
          </div>
        )
      case 'mediapage':
        return (
          <ParallaxImage
            className={classes.mediaPageImage}
            image={image?.fluid || {}}
            alt={image?.description || ''}
            focalPoint={focalPoint}
          />
        )
      default:
        return (
          <ParallaxImage
            className={
              !isLarge && imageMobile ? classes.imageMobile : classes.image
            }
            image={
              !isLarge && imageMobile ? imageMobile?.fluid : image?.fluid || {}
            }
            alt={image?.description || ''}
            focalPoint={focalPoint}
          />
        )
    }
  }

  const renderCard = () => {
    return (
      <Card
        className={classes.card}
        bar={cardBar}
        bind={'leftAuto'}
        stage
        transparentCard
        aria-label="Stage Card"
      >
        {subtitle && <span className={classes.subline}>{subtitle}</span>}
        {title && (
          <Headline level={1} className={classes.headline}>
            {title}
          </Headline>
        )}
        {copy && <div>{copy}</div>}
        {buttons &&
          buttons.map((button, i) => {
            if (button.create) {
              return (
                <CreateContentButton
                  key={`button${i}`}
                  className={classes.button}
                  icon={button.icon}
                  color={button.color}
                  type={button.type}
                >
                  {button.label}
                </CreateContentButton>
              )
            } else {
              return (
                <Button
                  key={`button${i}`}
                  className={classes.button}
                  to={button.link}
                  icon={button.icon}
                  color={button.color}
                  type={button.type}
                >
                  {button.label}
                </Button>
              )
            }
          })}
        {date && <div className={classes.articleDate}>{date}</div>}
      </Card>
    )
  }

  const renderAuthor = () => {
    return (
      <div className={classes.authorWrapper}>
        <div className={classes.author}>
          {author.avatar && (
            <div className={classes.authorAvatar}>
              <img src={author.avatar} alt={author.name} />
            </div>
          )}
          <div className={classes.authorDetails}>
            <div>
              {author.name}
              {author.description && (
                <>
                  ,<br />
                  {author.description}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={clsx(classes.stageRoot, {
        [classes.alignTop]: alignTop,
        [classes.noMargin]: noMargin,
      })}
      role="region"
      aria-label={
        ariaLabel ||
        intl.formatMessage({
          id: 'arialabel.stage',
        })
      }
    >
      {renderImage()}
      {renderCard()}
      {author && renderAuthor()}
    </div>
  )
}

Stage.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.oneOf(['default', 'split', 'generic']),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  copy: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      color: PropTypes.string,
      icon: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  image: PropTypes.object,
  imageMobile: PropTypes.object,
  focalPoint: PropTypes.oneOf(Object.keys(focalPointsTable)),
  cardBar: PropTypes.oneOf(['yellow', 'red', 'grey']),
  date: PropTypes.string,
  author: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    avatar: PropTypes.string,
  }),
  alignTop: PropTypes.bool,
  noMargin: PropTypes.bool,
  ariaLabel: PropTypes.string,
}

Stage.defaultProps = {
  type: 'default',
  cardBar: 'yellow',
}

export default Stage
